const imagesStyles = {
    img: {
      padding: '15px', 
      margin: '2vw auto',
      maxWidth: "100%",
      height: "auto",

      "@media (min-width: 768px)": {
        maxWidth: "50%"
      },
    },
};

export default imagesStyles;
